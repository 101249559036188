import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// get involved
import partnerships from "../images/icons/handshake.svg"
// import sponsorships from "../images/icons/feedback.svg"
import sponsorships from "../images/icons/handheart.svg"
import volunteers from "../images/icons/people.svg"


const GetInvolvedPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container get-involved__container">
      <h1 className="container__header"> Get Involved </h1>

      <div className="get-involved__npo-number">
          We are a registered non profit organisation with NPO number 210-208 NPO. For more information please <a href="mailto: ridhwana@kasimaths.org">email</a> our team. 
      </div>

      <div>
        <form class="form__contact-us" action="https://formcarry.com/s/ry90ewRzM" method="POST" accept-charset="UTF-8">
          <div className="form__group">
            <div className="form__section">
              <div class="form__item">
                <input type="text" name="firstName" placeholder="First Name"/>
              </div>
              <div class="form__item">
                <input type="text" name="lastName" placeholder="Last Name"/>
              </div>
              <div class="form__item">
                <input type="text" name="email" placeholder="Email Address"/>
              </div>
              <div class="form__item">
                <input type="text" name="contactNumber" placeholder="Contact Number"/>
              </div>
              <div class="form__item">
                <select type="text" name="interest">
                  <option placeholder="I'm interested in">I'm interested in</option>
                  <option value="partnering">partnering</option>
                  <option value="sponsoring">sponsoring</option>
                  <option value="volunteering">volunteering</option>
                </select>
              </div>
            </div>
            <div className="form__section">
              <div class="form__item form__description">
                <textarea rows="8" name="description" placeholder="Tell us more.."/>
              </div>
            </div>
          </div>
          <div className="btn-container--center">
            <button class="btn btn-primary form__button" type="submit" value="Submit">Submit</button>
          </div>
        </form>
      </div>

        <div>
          In order for the Kasi Maths program to be grow and thrive we rely on the community to help us attain our goals. We always welcome:
        </div>

        <div className="get-involved__types">
          <div className="get-involved__type">
            <div className="get-involved__type-description">
              <div className="get-involved__type-image">
                <img src={partnerships} alt="partnerships"/>
              </div>
              <div className="get-involved__type-text">
                <div className="get-involved__type-name">Partnerships</div>
                <p>
                  We are always looking to partner and collaborate with other organisations that want to walk a path of improving education, particularly the STEM field, in under developed areas. We are eager to persue partnerships that allow us to other make an even bigger impact by combining forces.
                </p>
                </div>
            </div>
          </div>

          <div className="get-involved__type">
            <div className="get-involved__type-description">
              <div className="get-involved__type-image">
                <img src={sponsorships} alt="sponsorships"/>
              </div>
              <div className="get-involved__type-text">
                <div className="get-involved__type-name">Sponsorships</div>
                <p>
                  Since Kasi Maths is a non profit and does not sell or charge for any seervices that we offer to the students, we rely solely on sponsorships to be able to scale and run sustainably. We are open to any sort of sponsorships whether it be monetary or in the form of equipment. Some of our costs to scale would include:
                  <ul>
                    <li>Venues to open more programs</li>
                    <li>STEM realted books</li>
                    <li>Engineering and robotics kits</li>
                    <li>Computers/Tablets to allow for scale</li>
                    <li>Science Kits</li>
                    <li>Stationary</li>
                    <li>Transportantion for our volunteers</li>
                  </ul>
                  We allow transparency into our costs.
                </p>
              </div>
            </div>
          </div>

          <div className="get-involved__type">
            <div className="get-involved__type-description">
              <div className="get-involved__type-image">
                <img src={volunteers} alt="volunteers"/>
              </div>
              <div className="get-involved__type-text">
                <div className="get-involved__type-name">Volunteers</div>
                <p>
                  Volunteers are at the &#10084; of the Kasi Maths Program.
                  They help us to grow and to sustain the daily running of the program.

                  We are looking for different types of volunteers. Some of the roles iclude:
                  <ul>
                    <li>
                      Tutors who spend every Monday and Wednesday with our students from 3pm to 4:30pm. Tutors must be able to explain Grade 8 and 9 mathematics concepts. They must be responsible and patient with our students, helping them not only through our curriculum, but also inspiring them to be better.</li>
                    <li>
                      An accountant/tax practitioner that can help us with our expenses and budget
                    </li>
                    <li>
                      A strategy partner that can help to plan out curriculums for the student incorporating the latest technologies to inspire and expose the students to potential careers that they can explore later in their lives.
                    </li>
                    <li>
                      Advisors that have experience running non profit organisations and can bring diverse and creative ways of taking the program forward and making an impact
                    </li>
                  </ul>
                  <p>
                    Even if you do not fit into one of the roles above, we'd still like to hear from you if you think that you can contribute in some way. When contacting us, please help us to understand in what capacity and time you commit to the program.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>

    </div>
  </Layout>
)

export default GetInvolvedPage
